<template>
  <v-card
      class="mx-auto"
      elevation="6"
      height="100%"
      width="100%"
      @click="
      $router.push({
        name: 'Profile',
        params: { uid: sponsor.usersUid },
      })
    ">
    <v-card-title class="secondary rounded">
      <v-img class="rounded elevation-3" :src="baseURL + '/storage/pfps/' + sponsor.usersPfp" alt="Profile Picture" :aspect-ratio="1"/>
    </v-card-title>
    <v-card-title class="text-uppercase text-break primary--text">{{ sponsor.usersUid }}</v-card-title>
    <v-card-text>{{sponsor.usersDesc}}</v-card-text>
    <v-card-actions>
      <v-btn block color="primary">Profile</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapState} from "vuex";

//

export default {
  name: "SponsorCard",
  props: ["sponsor"],
  computed: mapState(["baseURL"]),
  methods: {}
}
</script>

<style scoped>

</style>