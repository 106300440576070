<template>
  <v-container>
    <h1 class="text-h2 text-break text-uppercase font-weight-medium">Sponsors</h1>
    <h3 class="text-h5 text-break mb-6">We value all of our sponsors for being able to keep this site ad free and running</h3>
    <v-row v-if="loaded">
      <v-col v-for="sponsor in sponsors" cols="12" md="6" lg="4">
        <SponsorCard :sponsor="sponsor" />
      </v-col>
      <v-col v-for="sponsor in sponsors" cols="12" md="6" lg="4">
        <SponsorCard :sponsor="sponsor" />
      </v-col>
      <v-col v-for="sponsor in sponsors" cols="12" md="6" lg="4">
        <SponsorCard :sponsor="sponsor" />
      </v-col>
    </v-row>
    <Loader :size="150" v-else />
  </v-container>
</template>

<script>
import Loader from "@/components/Loader";
import SponsorCard from "@/components/SponsorCard";
import {mapActions} from "vuex";

export default {
  components: {Loader, SponsorCard},
  name: "Sponsors",
  metaInfo: {
    title: "Sponsors",
    "http-equiv": "Content-Security-Policy",
    content: "upgrade-insecure-requests",
  },
  data: () => ({
    sponsors: [],
    loaded: false
  }),
  mounted() {
    this.fetchSponsors();
  },
  methods: {
    ...mapActions('data', ["postData"]),
    async fetchSponsors() {
      let response = await this.postData({
        url: "fetchSpoUsers",
      });
      try {
        response["users"].forEach((element) => {
          this.sponsors.push(element);
        });
        this.loaded = 1;
      } catch (error) {
        console.log("No Parts found: " + error);
        this.loaded = 2;
      }
    },
  }

}
</script>

<style scoped>

</style>